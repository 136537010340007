module.exports = {
  selectLanguage: "elige tu idioma",
  displayName: "Espanol",
  categories: "Categorias",
  read: "Leer",
  price: "Precio",
  lastUpdate: "Última actualización",
  free: "Ahora disponible gratis",
  Software: "Software",
  "Machine Learning": "Aprendizaje automático",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Etiquetas",
  Home: "Página de inicio",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Formulario de contacto",
  "Cancel": "Cancelar",
  "Submit": "Enviar",
  "Contact Message": "Agradecemos que nos envíe sus comentarios a nuestro sitio web.",
  "Flutter": "Flutter"
}
