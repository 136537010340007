import React from "react"
import Layout from "../layouts/es"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ListMainLayout from '../layouts/listmain'

const IndexPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Home" description="Página superior del canal.241. Puede encontrar sus artículos favoritos con varias categorías." lang="es" />
    <ListMainLayout data={props.data} langKey="es"/>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark (filter: {fields: {langKey: {eq: "es"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            author
            price
          }
          fields {
            slug
            langKey
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
